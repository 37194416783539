import React from 'react';
import Page from './pages/index'


function Index() {

  return (
    <div>
      <Page />
    </div>
  )
}



export default Index;